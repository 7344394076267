import * as React from "react";
import Layout from "../components/global/layout/layout";
import {graphql} from "gatsby";
import SmallHeader from "../components/global/small-header/small-header";

export const pageQuery = graphql`
  query SingleTherapyQuery {
    site {
      siteMetadata {
        title
        description
        titleSuffix
        titleDivider
      }
    }
  }
`;

export default props => {
  return (
    <Layout title={props.data.site.siteMetadata.title}
            description={props.data.site.siteMetadata.description}
            titleSuffix="Einzeltherapie"
            titleDivider={props.data.site.siteMetadata.titleDivider}>
      <SmallHeader title={'Die Einzeltherapie'}
                   backgroundImage={'/images/einzeltherapie/weber-toys.png'}/>
      <div className="container">
        <div className="row mt-5 mb-2">
          <div className="col-12">
            <p>
              In der Einzeltherapie wirkt vor allem die Beziehung zwischen Klient und Therapeut. Deshalb solltest du dir unbedingt die Freiheit nehmen, genau zu prüfen, wie es dir hier bei mir geht.
            </p>
            <p className={`pt-2`}>
              Kommt eine Therapie zu Stande, geht es darum, sich selbst besser wahrnehmen zu lernen, über eigene Gefühle und Gedanken zu reden und so ein besseres Verständnis für sich selbst zu entwickeln. Damit können wir die uns allen innewohnende Möglichkeit stärken, einen gesünderen Umgang mit uns selbst und damit kreative Lösungen zu finden. Je jünger der Klient ist, desto eher laufen diese Prozess über das therapeutische Spiel ab. Mit zunehmendem Alter wird das therapeutische Gespräch immer wichtiger.
            </p>
            <p className={`pt-2 mb-0`}>
              In der Regel finden die Sitzungen wöchentlich statt. Im späteren Verlauf der Therapie können die Abstände zwischen den Terminen vergrößert werden. Behandlungen dauern im Durchschnitt 6 Monate bis 2 Jahre.
            </p>
          </div>
        </div>
      </div>
    </Layout>
  );
}
